import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Skeleton, Space } from "antd";
import { VOICE_API_ENDPOINT } from "utils";
import { fetchWrapper } from "utils/fetchWrapper";
import { getPerformedTestDetailColumns } from "./PerformedTestDetailCol";
import { getPerformedTestDetailStepsColumns } from "./PerformedTestDetailStepsCol";
import StlTable from "components/shared/StlTable";

import {
  getActions,
  getPerformedTestsDetailById,
} from "features/slices/voice/voicePerformedTestDetail/voicePerformedTestDetailThunk";
import StlButton from "components/shared/StlButton";

const PerformedTestDetail = ({ detail }) => {
  const dispatch = useDispatch();

  const [performedDetail, setPerformedDetail] = useState(null);
  const [actionsData, setActionsData] = useState(null);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const isLoading = useSelector(
    (store) => store.voicePerformedTestsDetail.loading
  );

  const performedDetailStepsColumns = getPerformedTestDetailStepsColumns();
  const userEmail = useSelector((store) => store.personalInfo.userEmail);

  const removeFirstAndLastElem = (elements) => {
    return elements.slice(1, -1);
  };

  useEffect(() => {
    const ac = new AbortController();
    dispatch(getPerformedTestsDetailById(detail.id)).then((testDetail) => {
      setPerformedDetail(testDetail.payload);
    });

    dispatch(getActions()).then((actions) => {
      setActionsData(actions.payload);
    });
    return () => ac.abort(); // Abort both fetches on unmount
  }, [dispatch, detail]);

  let performedDetailColumns;
  if (actionsData) {
    performedDetailColumns = getPerformedTestDetailColumns(
      actionsData,
      isBtnLoading
    );
  }

  return (
    <Space
      direction="vertical"
      size="small"
      style={{
        display: "flex",
      }}
    >
      <div style={{ textAlign: "right" }}>
        <StlButton
          iconName="update"
          buttonStyle={{ marginRight: 8 }}
          onClickHandler={() => {
            dispatch(getPerformedTestsDetailById(detail.id)).then(
              (testDetail) => {
                setPerformedDetail(testDetail.payload);
              }
            );
          }}
        />
      </div>
      {!isLoading &&
        performedDetailColumns &&
        performedDetail &&
        actionsData && (
          <StlTable
            dataSource={[performedDetail]}
            columns={performedDetailColumns}
            size="small"
            rowKey={"id"}
          />
        )}
      {isLoading && <Skeleton active />}
      {!isLoading && performedDetail && (
        <StlTable
          dataSource={removeFirstAndLastElem(performedDetail.steps)}
          loading={isLoading}
          columns={performedDetailStepsColumns}
          rowKey={"idScenarioStep"}
          size="small"
          pagination={{ position: ["topLeft"] }}
        />
      )}
    </Space>
  );
};

export default PerformedTestDetail;
