import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "antd";
import {
  setIsEditing,
  setIsBeingAdded,
  setLoading,
  setTabActiveKey,
} from "features/slices/voice/voiceScenariosSlice";
import {
  editVoiceScenario,
  getOneVoiceScenarioById,
} from "features/slices/voice/voiceScenariosThunk";
import { useParams } from "react-router-dom";
import {
  deepCopy,
  openNotificationWithIcon,
  SCENARIO_API_ENDPOINT,
  VOICE_API_ENDPOINT,
} from "utils";
import StlTable from "components/shared/StlTable";
import AlertMessage from "components/shared/AlertMessage";

import { useHover } from "hooks/useHover";
import { fetchWrapper } from "utils/fetchWrapper";
import VoiceScenarioDescriptionEditableCell from "./VoiceScenarioDescriptionEditableCell";
import { getVoiceScenariosDescriptionColumns } from "./VoiceScenarioDescriptionColumns";
import { getPerformedTestsByIdScenario } from "features/slices/voice/voicePerformedTest/voicePerformedTestThunk";

const VoiceScenarioDescription = (props) => {
  const params = useParams();

  const scenarios = useSelector((store) =>
    store.voiceScenarios.scenarios.filter((e) => e.id === params.scenarioID)
  );

  const loading = useSelector((store) => store.voiceScenarios.loading);
  const failed = useSelector((store) => store.voiceScenarios.failed);
  const isEditing = useSelector((store) => store.voiceScenarios.editing);

  const { buttonStyle, handleMouseEnter, handleMouseLeave } = useHover();

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getOneVoiceScenarioById(params.scenarioID));
  }, [dispatch, params.scenarioID]);

  const edit = (record) => {
    dispatch(setIsEditing(true));
    record.toNumber = record.toNumber ? record.toNumber : null;
    form.setFieldsValue({
      ...record,
    });
  };

  const execute = (record) => {
    const scenario = { ...record };
    console.log(scenario);
    dispatch(setLoading(true));
    fetchWrapper
      .post(VOICE_API_ENDPOINT + "vvb/run/" + scenario.id)
      .then(() => {
        dispatch(setLoading(false));
        openNotificationWithIcon("success", "Esecuzione avviata");
        dispatch(setTabActiveKey("performed"));
        dispatch(getPerformedTestsByIdScenario(scenario.id));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        openNotificationWithIcon("error", error);
      });
  };

  const cancel = () => dispatch(setIsEditing(false));

  const save = async () => {
    const newEntry = await form.validateFields();
    newEntry.toNumber = newEntry.toNumber;
    const row = deepCopy(scenarios[0]);
    try {
      dispatch(setLoading(true));
      const theScenario = await fetch(
        SCENARIO_API_ENDPOINT + "voice/scenario/" + row.id
      )
        .then((res) => (res.ok ? res : null))
        .then((scenario) => scenario.json());

      const editedScenario = { ...theScenario, ...newEntry };
      await dispatch(editVoiceScenario(editedScenario));
      await dispatch(getOneVoiceScenarioById(params.scenarioID));
    } catch {
      console.log("Failed to connect to server.");
    }

    dispatch(setIsBeingAdded(false));
    dispatch(setIsEditing(false));
  };

  const VoiceColumnsConfig = {
    isEditing,
    save,
    edit,
    cancel,
    execute,
    buttonStyle,
    handleMouseEnter,
    handleMouseLeave,
  };
  const columns = getVoiceScenariosDescriptionColumns(VoiceColumnsConfig);

  const prepareScenario = (scenarios) => {
    const copiedScenarios = deepCopy(scenarios);
    copiedScenarios.forEach((e) => (e.key = e.id));
    return copiedScenarios;
  };

  const scenario = prepareScenario(scenarios);

  return (
    <Form form={form}>
      <div>{failed ? <AlertMessage /> : null}</div>
      {scenarios.length > 0 ? (
        <StlTable
          components={{
            body: {
              cell: VoiceScenarioDescriptionEditableCell,
            },
          }}
          dataSource={scenario}
          columns={columns}
          loading={loading}
        />
      ) : (
        <h2 style={{ height: "100px" }}>Scenario not found</h2>
      )}
    </Form>
  );
};

export default VoiceScenarioDescription;
