import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "antd";

import {
  setIsEditing,
  setIsBeingAdded,
  setLoading,
} from "features/slices/configuration/sentinelConfigurationSlice";

import {
  editConfiguration,
  getConfigurationById,
  SENTINEL_CONFIG_URL,
} from "features/slices/configuration/sentinelConfigurationThunk";
import StlTable from "components/shared/StlTable";
import AlertMessage from "components/shared/AlertMessage";
import { useHover } from "hooks/useHover";
import { getConfigurationColumns } from "./SentinelConfigurationColumns";
import { deepCopy } from "utils";
import { addKeyProperty } from "utils/transform/transformUtils";
import ConfigurationEditableCell from "./ConfigurationEditableCell";
import { useParams } from "react-router-dom";

const SentinelConfigurationDescription = (props) => {
  const params = useParams();
  const { buttonStyle, handleMouseEnter, handleMouseLeave } = useHover();
  const configurationsRaw = useSelector((store) =>
    store.sentinelConfiguration.configurations.filter(
      (e) => e.id === params.configurationID
    )
  );

  const loading = useSelector((store) => store.sentinelConfiguration.loading);
  const failed = useSelector((store) => store.sentinelConfiguration.failed);
  const isEditing = useSelector((store) => store.sentinelConfiguration.editing);
  const isBeingAdded = useSelector(
    (store) => store.sentinelConfiguration.isBeingAdded
  );

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getConfigurationById(params.configurationID));
  }, [dispatch, params.configurationID]);

  const edit = (record) => {
    dispatch(setIsEditing(true));
    record.toNumber = record.toNumber;
    form.setFieldsValue({
      ...record,
    });
  };

  const cancel = () => {
    dispatch(setIsEditing(false));
  };

  const save = async () => {
    const newEntry = await form.validateFields();
    newEntry.toNumber = newEntry.toNumber;
    const row = JSON.parse(JSON.stringify(configurationsRaw[0]));
    try {
      dispatch(setLoading(true));
      const theConfiguration = await fetch(SENTINEL_CONFIG_URL + row.id)
        .then((res) => (res.ok ? res : null))
        .then((configuration) => configuration.json());

      const editedConfiguration = { ...theConfiguration, ...newEntry };
      await dispatch(editConfiguration(editedConfiguration));
      await dispatch(getConfigurationById(params.configurationID));
    } catch {
      console.log("Failed to connect to server.");
    }

    dispatch(setIsBeingAdded(false));
    dispatch(setIsEditing(false));
  };

  const columnsOptions = {
    buttonStyle,
    handleMouseEnter,
    handleMouseLeave,
    isEditing,
    isBeingAdded,
    edit,
    cancel,
    save,
  };
  const columns = getConfigurationColumns(columnsOptions);

  const prepareConfigurations = (configurationsRaw) => {
    const copiedConfigs = deepCopy(configurationsRaw);
    return addKeyProperty(copiedConfigs);
  };

  const configurations = prepareConfigurations(configurationsRaw);

  return (
    <Form form={form}>
      <div>{failed ? <AlertMessage /> : null}</div>
      {configurations.length > 0 ? (
        <StlTable
          components={{
            body: {
              cell: ConfigurationEditableCell,
            },
          }}
          dataSource={configurations}
          columns={columns}
          loading={loading}
        />
      ) : (
        <h2 style={{ height: "100px" }}>Configuration not found</h2>
      )}
    </Form>
  );
};

export default SentinelConfigurationDescription;
